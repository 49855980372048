import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";

const imgs = [
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fclashdetection%2FClashing%20Graphic%201.png?alt=media&token=766c34f7-018a-4b81-af1b-59924411f618",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fclashdetection%2FClashing%20Graphic%202.png?alt=media&token=abed1b56-1d5c-4460-9e86-c01debd4bffa",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fclashdetection%2FPerspective%20MEP%20Work_Thumbnail%20Pic.jpg?alt=media&token=58b3c2a7-f34d-4125-b384-7be20d12ba14",
];

export default function ClashDetectionPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.CLASHDETECTION}>
      <Seo
        title="Clash Detection | Robotic Imaging"
        description="Learn more about clash detection and its importance to BIM."
      />
      <header className="site__header"></header>

      <main className="content clashdetection-content">
        <img
          src={imgs[1]}
          alt=""
          className="clashdetection-img clashdetection-img2"
          loading="lazy"
        />

        <h4 className="clashdetection-h4">What is clash detection?</h4>
        <p>
          A "clash" is the result of two elements in your design taking up the
          same space. In Building Information Modeling (BIM), clash detection is
          the technique of identifying if, where, or how two parts of the
          building interfere with one another.
        </p>

        <h4 className="clashdetection-h4">What is a hard clash?</h4>
        <p>
          Hard clashes occur when two or more components are occupying the same
          space or are interfering with each other. For instance, in the image
          above an existing point cloud duct is routed through a new ductwork
          path.
        </p>

        <h4 className="clashdetection-h4">What is a soft clash?</h4>
        <p>
          A soft clash indicates that an object has not been given sufficient
          geometric tolerances in the design phase or if its buffer zone has
          been violated. An example of a soft clash would be failing to leave
          sufficient space for maintenance workers to service an air
          conditioning unit. If the building design lacks sufficient space, it
          will trigger a soft clash.
        </p>

        <h4 className="clashdetection-h4">What is a workflow clash?</h4>
        <p>
          While not an object clash, in BIM, a workflow clash results from
          inconsistent or conflicting building information. For example, an HVAC
          maintenance schedule that does not align with the scheduled delivery
          of spare parts.
        </p>

        <h4 className="clashdetection-h4">Clash detection uses:</h4>
        <ul className="clashdetection-list">
          <li>Assess structural integrity</li>
          <li>Assess energy efficieny</li>
          <li>Reduce instances of human error</li>
          <li>Prevent expensive late-stage redesign</li>
          <li>Plan efficient building operation</li>
        </ul>
        <ul className="clashdetection-list">
          <li>Ensure compliance with regulatory requirements</li>
          <li>
            Ensure the master model satisfies safety and performance
            requirements
          </li>
          <li>Clash detection between existing services and new services</li>
        </ul>

        <h4 className="clashdetection-h4">
          Benefits of clash detection services:
        </h4>
        <p>
          SAVE TIME: Once clashes are identified, they can be reviewed and
          resolved before advancing to the construction process. It also results
          in easier sequencing and/or scheduling. MORE ACCURACY: Accurate
          estimates for the entire construction phase, leading to fewer
          accidents Reduced Risk: Fewer errors on all stages of a construction
          project. Smaller risks, lower overall costs, and drastically decreased
          material waste.
        </p>

        <img
          src={imgs[2]}
          alt=""
          className="clashdetection-img clashdetection-img3"
          loading="lazy"
        />

        <h4 className="clashdetection-h4">Why use it?</h4>
        <p>
          BIM clash detection has become one of the features that greatly
          improves modern construction project processes, especially when it
          comes to collaboration between different stakeholders. Clash detection
          is a much needed breath of fresh air for an industry that already has
          a lot of coordination responsibilities!
        </p>
      </main>
    </SiteWrapper>
  );
}
